<template>
  <div id="checkout">
    <div class="container">
      <div class="row" v-show="!isThankYouPage">
        <div class="col-sm-12 col-xs-12 col-md-7 pb70">
          <div class="checkout-title">
            <div class="flex16">
              <div :class="isPartnerLogo()" class="checkout-logo"></div>
              <div>
                <h2 v-if="displayPartnerData()" class="pl5 cl-accent">
                  {{ $t(isSbcr() ? 'Ski Bike Centrum Radotín' : 'Highland Sport') }}
                </h2>
                <h2 v-if="!displayPartnerData()" class="pl5 cl-accent">
                  {{ $t('Rent') }}
                </h2>
                <h5 v-if="!displayPartnerData()">
                  {{ $t('Partner') }}: {{ partnerName }}
                </h5>
              </div>
            </div>
          </div>
          <additional-services class="relative xspr20" :is-active="activeSection.additionalServices" v-if="!isVirtualCart" />
          <personal-details
            class="relative xspr20"
            :is-active="activeSection.personalDetails"
            :focused-field="focusedField"
          />
          <shipping class="relative cl-accent xspr20" :is-active="activeSection.shipping" v-if="!isVirtualCart" />
          <return-shipping class="relative cl-accent xspr20" :is-active="activeSection.returnShipping" v-if="!isVirtualCart" />
          <payment class="relative cl-accent xspr20" :is-active="activeSection.payment" />
          <order-review class="relative cl-accent" :is-active="activeSection.orderReview" />
          <div id="custom-steps" />
        </div>
        <div class="hidden-xs col-sm-5 bg-cl-secondary">
          <cart-summary />
        </div>
      </div>
    </div>
    <thank-you-page v-show="isThankYouPage" />
  </div>
</template>

<script>
import Checkout from '@vue-storefront/core/pages/Checkout'

import PersonalDetails from 'theme/components/core/blocks/Checkout/PersonalDetails'
import Shipping from 'theme/components/core/blocks/Checkout/Shipping'
import ReturnShipping from 'theme/components/core/blocks/Checkout/ReturnShipping'
import AdditionalServices from 'theme/components/core/blocks/Checkout/AdditionalServices'
import Payment from 'theme/components/core/blocks/Checkout/Payment'
import OrderReview from 'theme/components/core/blocks/Checkout/OrderReview'
import CartSummary from 'theme/components/core/blocks/Checkout/CartSummary'
import ThankYouPage from 'theme/components/core/blocks/Checkout/ThankYouPage'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { OrderModule } from '@vue-storefront/core/modules/order'
import { mapGetters } from 'vuex'
import config from 'config'
import sbcrConfig from '@vue-storefront/core/partner-config/config.sbcr'
import highlandSportConfig from '@vue-storefront/core/partner-config/config.highlandsport'

export default {
  components: {
    AdditionalServices,
    PersonalDetails,
    Shipping,
    ReturnShipping,
    Payment,
    OrderReview,
    CartSummary,
    ThankYouPage
  },
  computed: {
    ...mapGetters({
      getCurrentProduct: 'product/getCurrentProduct',
    }),
    partnerName () {
      return this.getCurrentProduct?.rental_info?.name ?? 'Anyrent'
    },
  },
  mixins: [Checkout],
  beforeCreate () {
    registerModule(OrderModule)
  },
  methods: {
    getPartnerId () {
      return this.$ssrContext?.partnerData.id || config.partnerData.id;
    },
    isSbcr () {
      return this.getPartnerId() === sbcrConfig.id;
    },
    isHighland () {
      return this.getPartnerId() === highlandSportConfig.id;
    },
    displayPartnerData () {
      return config.partnerData.displayPartnerPages;
    },
    isPartnerLogo () {
      if (config.partnerData.displayPartnerPages) {
        return 'partner-logo';
      }
    },
    notifyPastDate () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Your reservation dates are in past. The cart will be reseted, please select new products and dates'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyEmptyCart () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Shopping cart is empty. Please add some products before entering Checkout'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyOutStock (chp) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: chp.name + this.$t(' is out of stock!'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyNotAvailable () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t('Some of the ordered products are not available!'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyStockCheck () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('Stock check in progress, please wait while available stock quantities are checked'),
        action1: { label: this.$t('OK') }
      })
    },
    notifyNoConnection () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.$t('There is no Internet connection. You can still place your order. We will notify you if any of ordered products is not available because we cannot check it right now.'),
        action1: { label: this.$t('OK') }
      })
    }
  }
}
</script>

<style lang="scss">
  @import '~theme/css/base/text';
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  @import '~theme/css/variables/assets';
  @import '~theme/css/helpers/functions/asset';
  $bg-secondary: color(secondary, $colors-background);
  $color-tertiary: color(tertiary);
  $color-secondary: color(secondary);
  $color-error: color(error);
  $color-white: color(white);
  $color-black: color(black);
  $logo-src: asset(square-logo);
  $bg-partner: color(partner);

  #checkout {
    .number-circle {
      width: 35px;
      height: 35px;
      margin-left: 8px;

      @media (max-width: 768px) {
        width: 25px;
        height: 25px;
        line-height: 25px;
      }
    }
    .radioStyled {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 16px;
      line-height: 30px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 1px solid $bg-partner;

        &:after {
          content: "";
          position: absolute;
          display: none;
          top: 3px;
          left: 3px;
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background: $bg-partner;
        }
      }

      input:checked ~ .checkmark:after {
        display: block;
      }
    }
  }

  .flex16 {
    display: flex;
    gap: 16px;
  }

  .checkout-title {
    padding-top: 36px;
    @media (max-width: 767px) {
      // background-color: $bg-partner;
      margin-bottom: 25px;

      h2 {
        font-size: 36px;
      }
      h5 {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 575px) {
    .checkout-title {
      text-align: center;
    }

    .checkout-title h2 {
      font-size: 32px;
    }

    .partner-logo {
        margin-left: 20px;
    }

    .xspr20 {
      padding-right: 20px;
    }

    .xspl40 {
      padding-left: 40px;
    }
  }

  
  @media (max-width: 767px) {
    .xspl20 {
      padding-left: 20px;
    }    
  }

  .checkout-logo {
    background-image: $logo-src;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 124px;
    height: 124px;
  }

  .partner-logo {
    width: 100px;
    height: 100px;
  }
</style>
