<template>
  <div>
    <spinner v-if="sending" />
    <div v-if="!sending && OnlineOnly && !discount" class="col-xs-12 coupon-wrapper">
      <div class="coupon-input">
        <div class="row pl5">
          <base-input :placeholder="$t('Discount code')"
                      class="col-xs-8 "
                      type="text" id="couponinput" :autofocus="true" v-model.trim="couponCode"
          />

          <div class="col-xs-4 pt20">
            <span class="ml15 underline pointer" v-if="!sending" @click="setCoupon">{{ $t("Submit code") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!sending && discount">
      <div class="flex align-items-center">
        <span class="block font-size-18">{{ $t('Discount') }}: <span class="bold">{{ discount.value | price(storeView) }}</span></span>
        <span />
        <cross-button class="mt3" @click="clearCoupon" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from './Spinner';
import VueOfflineMixin from 'vue-offline/mixin'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import CrossButton from './CrossButton';

export default {
  name: 'Discount',
  components: {
    CrossButton,
    Spinner,
    BaseInput
  },
  data () {
    return {
      couponCode: '',
      sending: false
    };
  },
  computed: {
    ...mapGetters({
      appliedCoupon: 'cart/getCoupon',
      totals: 'cart/getTotals'

    }),
    storeView () {
      return currentStoreView();
    },
    discount () {
      return this.totals.find(t => t.code === 'discount');
    }
  },
  mixins: [VueOfflineMixin],
  methods: {
    ...mapActions({
      applyCoupon: 'cart/applyCoupon'
    }),
    async clearCoupon () {
      this.sending = true;
      await this.$store.dispatch('cart/removeCoupon');
    },
    async setCoupon () {
      if (!this.couponCode || this.couponCode === '') {
        return;
      }
      this.sending = true;
      const couponApplied = await this.applyCoupon(this.couponCode);
      this.couponCode = '';
      if (!couponApplied) {
        await this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: this.$t('You\'ve entered an incorrect coupon code. Please try again.'),
          action1: { label: this.$t('OK') }
        });
      }
    }
  },
  watch: {
    totals () {
      if (this.sending) {
        this.sending = false
      }
    }
  }
};
</script>
<style scoped>
.button-outline {
  min-width: 190px;
  padding: 0;
  height: 42px;
}

.underline {
 border-bottom: 1px solid black;
}

.bold {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}

.font-size-18 {
  font-size: 18px;
}

.align-items-center {
  align-items: center;
}

@media (max-width: 575px) {
.remove-discount-button {
display: flex;
  justify-content: center;
 }
}

</style>
