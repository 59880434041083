import Vue from 'vue'
import i18n from '@vue-storefront/i18n'
import config from 'config'
import VueOfflineMixin from 'vue-offline/mixin'
import { mapGetters } from 'vuex'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import Composite from '@vue-storefront/core/mixins/composite'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers'
import { Logger } from '@vue-storefront/core/lib/logger'
import dayjs from 'dayjs'

export default {
  name: 'Checkout',
  mixins: [Composite, VueOfflineMixin],
  data () {
    return {
      stockCheckCompleted: false,
      stockCheckOK: false,
      confirmation: null, // order confirmation from server
      activeSection: {
        additionalServices: true,
        personalDetails: false,
        shipping: false,
        returnShipping: false,
        payment: false,
        orderReview: false
      },
      order: {},
      personalDetails: {},
      shipping: {},
      returnShipping: {},
      shippingMethod: {},
      payment: {},
      orderReview: {},
      cartSummary: {},
      validationResults: {
        personalDetails: { $invalid: true },
        shipping: { $invalid: true },
        payment: { $invalid: true }
      },
      focusedField: null
    }
  },
  computed: {
    ...mapGetters({
      isVirtualCart: 'cart/isVirtualCart',
      isThankYouPage: 'checkout/isThankYouPage',
      getUserNote: 'checkout/getUserNote',
      getReturnShippingDetails: 'checkout/getReturnShippingDetails',
      reservations: 'reservations/getCurrentDates',
      productsInCart: 'cart/getCartItems'
    })
  },
  async beforeMount () {
    await this.$store.dispatch('checkout/load')
    this.$bus.$emit('checkout-after-load')
    this.$store.dispatch('checkout/setModifiedAt', Date.now())
    // TODO: Use one event with name as apram
    this.$bus.$on('cart-after-update', this.onCartAfterUpdate)
    this.$bus.$on('cart-after-delete', this.onCartAfterUpdate)
    this.$bus.$on('checkout-after-personalDetails', this.onAfterPersonalDetails)
    this.$bus.$on('checkout-after-shippingDetails', this.onAfterShippingDetails)
    this.$bus.$on('checkout-after-returnShippingDetails', this.onAfterReturnShippingDetails)
    this.$bus.$on('checkout-after-returnShippingDetailsChange', this.onAfterReturnShippingDetailsChange)
    this.$bus.$on('checkout-after-additionalServices', this.onAfterAdditionalServices)
    this.$bus.$on('checkout-after-additionalServicesChanged', this.onAfterAdditionalServicesChange)
    this.$bus.$on('checkout-after-paymentDetails', this.onAfterPaymentDetails)
    this.$bus.$on('checkout-after-cartSummary', this.onAfterCartSummary)
    this.$bus.$on('checkout-before-placeOrder', this.onBeforePlaceOrder)
    this.$bus.$on('checkout-do-placeOrder', this.onDoPlaceOrder)
    this.$bus.$on('checkout-before-edit', this.onBeforeEdit)
    this.$bus.$on('order-after-placed', this.onAfterPlaceOrder)
    this.$bus.$on('checkout-before-shippingMethods', this.onBeforeShippingMethods)
    this.$bus.$on('checkout-after-shippingMethodChanged', this.onAfterShippingMethodChanged)
    this.$bus.$on('checkout-after-validationError', this.focusField)
    if (!this.isThankYouPage) {
      this.$store.dispatch('cart/load', { forceClientState: true }).then(() => {
        if (this.$store.state.cart.cartItems.length === 0) {
          this.notifyEmptyCart()
          this.$router.push(this.localizedRoute('/'))
        } else {
          this.stockCheckCompleted = false
          const checkPromises = []
          for (const product of this.$store.state.cart.cartItems) { // check the results of online stock check
            if (product.onlineStockCheckid) {
              checkPromises.push(new Promise((resolve, reject) => {
                StorageManager.get('syncTasks').getItem(product.onlineStockCheckid, (err, item) => {
                  if (err || !item) {
                    if (err) Logger.error(err)()
                    resolve(null)
                  } else {
                    product.stock = item.result
                    resolve(product)
                  }
                })
              }))
            }
          }
          Promise.all(checkPromises).then((checkedProducts) => {
            this.stockCheckCompleted = true
            this.stockCheckOK = true
            for (const chp of checkedProducts) {
              if (chp && chp.stock) {
                if (!chp.stock.is_in_stock) {
                  this.stockCheckOK = false
                  chp.errors.stock = i18n.t('Out of stock!')
                  this.notifyOutStock(chp)
                }
              }
            }
          })
        }
      })
    }
    const storeView = currentStoreView()
    let country = this.$store.state.checkout.shippingDetails.country
    if (!country) country = storeView.i18n.defaultCountry
    this.$bus.$emit('checkout-before-shippingMethods', country)
    this.checkDateIsNotInPast()
  },
  beforeDestroy () {
    this.$store.dispatch('checkout/setModifiedAt', 0) // exit checkout
    this.$bus.$off('cart-after-update', this.onCartAfterUpdate)
    this.$bus.$off('cart-after-delete', this.onCartAfterUpdate)
    this.$bus.$off('checkout-after-personalDetails', this.onAfterPersonalDetails)
    this.$bus.$off('checkout-after-shippingDetails', this.onAfterShippingDetails)
    this.$bus.$off('checkout-after-returnShippingDetails', this.onAfterReturnShippingDetails)
    this.$bus.$off('checkout-after-returnShippingDetailsChange', this.onAfterReturnShippingDetailsChange)
    this.$bus.$off('checkout-after-additionalServices', this.onAfterAdditionalServices)
    this.$bus.$off('checkout-after-additionalServicesChanged', this.onAfterAdditionalServicesChange)
    this.$bus.$off('checkout-after-paymentDetails', this.onAfterPaymentDetails)
    this.$bus.$off('checkout-after-cartSummary', this.onAfterCartSummary)
    this.$bus.$off('checkout-before-placeOrder', this.onBeforePlaceOrder)
    this.$bus.$off('checkout-do-placeOrder', this.onDoPlaceOrder)
    this.$bus.$off('checkout-before-edit', this.onBeforeEdit)
    this.$bus.$off('order-after-placed', this.onAfterPlaceOrder)
    this.$bus.$off('checkout-before-shippingMethods', this.onBeforeShippingMethods)
    this.$bus.$off('checkout-after-shippingMethodChanged', this.onAfterShippingMethodChanged)
    this.$bus.$off('checkout-after-validationError', this.focusField)
  },
  watch: {
    '$route': 'activateHashSection',
    'OnlineOnly': 'onNetworkStatusCheck'
  },
  methods: {
    // if date is in past we do not want to proceed with the order, as it would be nonsensical
    checkDateIsNotInPast () {
      const now = dayjs()
      let dateFrom = dayjs(this.reservations.from)
      if (!dateFrom.isValid()) {
        // date format could be non-iso, then try this format
        dateFrom = dayjs(this.reservations.from, 'YYYY-MM-DD-hh-mm')
        // check again if date is valid
        if (!dateFrom.isValid()) {
          this.resetCartGoHome()
          return
        }
      }
      if (dateFrom < now) {
        this.resetCartGoHome()
      }
    },
    async resetCartGoHome () {
      this.notifyPastDate()
      // empty cart
      await this.$store.dispatch('cart/deleteBatch', { productsToDelete: this.productsInCart }) //
      await this.$store.dispatch('cart/clear') // just clear the items without sync
      await this.$store.dispatch('cart/sync', { forceClientState: true })
      // reset dates in vuex
      this.$store.dispatch('reservations/setDateFrom', '')
      this.$store.dispatch('reservations/setDateTo', '')
      // redirect
      this.$router.push(this.localizedRoute('/'))
    },
    onCartAfterUpdate (payload) {
      if (this.$store.state.cart.cartItems.length === 0) {
        this.notifyEmptyCart()
        this.$router.push(this.localizedRoute('/'))
      }
    },
    async onAfterShippingMethodChanged (payload) {
      // adding returnshipping data from vuex
      payload.returnShippingDetails = this.getReturnShippingDetails

      this.$bus.$emit('totals-sync-on', true)
      await this.$store.dispatch('cart/syncTotals', { forceServerSync: true, methodsData: payload })
      this.$bus.$emit('totals-sync-on', false)
      this.shippingMethod = payload
    },
    onBeforeShippingMethods (country) {
      this.$store.dispatch('checkout/updatePropValue', ['country', country])
      this.$store.dispatch('cart/syncTotals', { forceServerSync: true })
      this.$forceUpdate()
    },
    async onAfterPlaceOrder (payload) {
      // console.log('CALL on-after-place-order: ', payload);
      const paymentMethodCode = payload.order.addressInformation.payment_method_code;
      // console.log('payment method code: ', paymentMethodCode);

      if (this.$store.state.checkout.personalDetails.createAccount) {
        await this.$store.dispatch('user/login', { username: this.$store.state.checkout.personalDetails.emailAddress, password: this.$store.state.checkout.personalDetails.password })
      }
      await this.$store.dispatch('checkout/setUserNote', '');

      if (paymentMethodCode === 'gopay') {
        this.$bus.$emit('gopay-go-fly')
      } else if (paymentMethodCode === 'checkmo') {
        const redirectUrlObject = await this.$store.dispatch('payment-card-dropshipping/getDropshippingPaymentUrl', payload);
        this.$store.dispatch('payment-card-dropshipping/redirectToPaygate', redirectUrlObject.result.paymentUrl);
      } else {
        this.confirmation = payload.confirmation
        this.$store.dispatch('checkout/setThankYouPage', true)
        this.$store.dispatch('user/getOrdersHistory', { refresh: true, useCache: true })
      }

      Logger.debug(payload.order)()
    },
    onBeforeEdit (section) {
      this.activateSection(section)
    },
    onBeforePlaceOrder (payload) {
    },
    onAfterCartSummary (receivedData) {
      this.cartSummary = receivedData
    },
    onDoPlaceOrder (additionalPayload) {
      if (this.$store.state.cart.cartItems.length === 0) {
        this.notifyEmptyCart()
        this.$router.push(this.localizedRoute('/'))
      } else {
        this.payment.paymentMethodAdditional = additionalPayload
        this.placeOrder()
      }
    },
    onAfterPaymentDetails (receivedData, validationResult) {
      this.payment = receivedData
      this.validationResults.payment = validationResult
      this.activateSection('orderReview')
      this.savePaymentDetails()
    },
    onAfterShippingDetails (receivedData, validationResult) {
      this.shipping = receivedData
      this.validationResults.shipping = validationResult
      this.activateSection('returnShipping')
      this.saveShippingDetails()

      const storeView = currentStoreView()
      storeView.tax.defaultCountry = this.shipping.country
    },
    onAfterReturnShippingDetailsChange () {
      const payload = this.createPayloadWithShippingDetails()
      this.onAfterShippingMethodChanged(payload)
    },
    onAfterReturnShippingDetails () {
      const payload = this.createPayloadWithShippingDetails()
      // update shipping innformation
      this.onAfterShippingMethodChanged(payload)
      // proceed to next section
      this.activateSection('payment')
      this.returnShipping = this.getReturnShippingDetails
    },
    onAfterAdditionalServices () {
      this.activateSection('personalDetails')
    },
    async onAfterAdditionalServicesChange (services) {
      this.$bus.$emit('totals-sync-on', true)
      this.$bus.$emit('loader-overlay-change', true)
      await this.$store.dispatch('cart/updateTotalsAfterAdditionalService', {services: services})
      this.$bus.$emit('totals-sync-on', false)
      this.$bus.$emit('loader-overlay-change', false)
    },
    createPayloadWithShippingDetails () {
      return {
        forceServerSync: true,
        carrier_code: this.shipping.shippingCarrier,
        country: this.shipping.country,
        returnShippingDetails: {}, // will be defined later
        method_id: this.shipping.shippingMethod,
        method_code: this.shipping.shippingCarrier,
        // payment_method: 'cashondelivery',
        shippingDetails: this.shipping
      };
    },
    onAfterPersonalDetails (receivedData, validationResult) {
      this.personalDetails = receivedData
      this.validationResults.personalDetails = validationResult

      if (this.isVirtualCart === true) {
        this.activateSection('payment')
      } else {
        this.activateSection('shipping')
      }
      this.savePersonalDetails()
      this.focusedField = null
    },
    onNetworkStatusCheck (isOnline) {
      this.checkConnection(isOnline)
    },
    checkStocks () {
      let isValid = true
      // for (const child of this.$children) {
      //   if (child.hasOwnProperty('$v')) {
      //     if (child.$v.$invalid) {
      //        console.log('error - child invalid')
      //        Check if child component is Personal Details.
      //        If so, then ignore validation of account creation fields.
      //        if (child.$v.hasOwnProperty('personalDetails')) {
      //          if (child.$v.personalDetails.$invalid) {
      //            isValid = false
      //            console.log('1')
      //            break
      //          }
      //        } else {
      //          isValid = false
      //          break
      //        }
      //     }
      //   }
      // }

      if (typeof navigator !== 'undefined' && navigator.onLine) {
        if (this.stockCheckCompleted) {
          if (!this.stockCheckOK) {
            isValid = false
            this.notifyNotAvailable()
          }
        } else {
          this.notifyStockCheck()
          isValid = false
        }
      }
      return isValid
    },
    activateHashSection () {
      if (!isServer) {
        var urlStep = window.location.hash.replace('#', '')
        if (this.activeSection.hasOwnProperty(urlStep) && this.activeSection[urlStep] === false) {
          this.activateSection(urlStep)
        } else if (urlStep === '') {
          this.activateSection('personalDetails')
        }
      }
    },
    checkConnection (isOnline) {
      if (!isOnline) {
        this.notifyNoConnection()
      }
    },
    activateSection (sectionToActivate) {
      for (const section in this.activeSection) {
        this.activeSection[section] = false
      }
      this.activeSection[sectionToActivate] = true
      if (!isServer) window.location.href = window.location.origin + window.location.pathname + '#' + sectionToActivate
    },
    // This method checks if there exists a mapping of chosen payment method to one of Magento's payment methods.
    getPaymentMethod () {
      let paymentMethod = this.payment.paymentMethod
      if (config.orders.payment_methods_mapping.hasOwnProperty(paymentMethod)) {
        paymentMethod = config.orders.payment_methods_mapping[paymentMethod]
      }
      return paymentMethod
    },
    prepareOrder () {
      this.order = {
        user_data: {
          email: this.personalDetails.emailAddress,
          first_name: this.personalDetails.firstName,
          last_name: this.personalDetails.lastName
        },
        user_id: this.$store.state.user.current ? this.$store.state.user.current.id.toString() : '',
        cart_id: this.$store.state.cart.cartServerToken ? this.$store.state.cart.cartServerToken.toString() : '',
        user_token: this.$store.state.user.token ? this.$store.state.user.token.toString() : undefined,
        products: this.$store.state.cart.cartItems,
        addressInformation: {
          shippingAddress: this.shipping || {},
          billingAddress: {
            region: this.payment.state,
            region_id: this.payment.region_id || 0,
            country_id: this.payment.country || 'CZ', // TODO check
            street: [this.payment.streetAddress, this.payment.apartmentNumber],
            company: this.payment.company,
            telephone: this.payment.phoneNumber,
            postcode: this.payment.zipCode,
            city: this.payment.city,
            firstname: this.payment.firstName,
            lastname: this.payment.lastName,
            email: this.personalDetails.emailAddress,
            region_code: this.payment.region_code || '',
            vat_id: this.payment.taxId,
            vat_id_ico: this.payment.taxIdIco,
            company_id: this.payment.companyId,
            isVatApplicable: this.payment.isVatApplicable
          },
          shippingMethodId: this.shippingMethod.method_id ? this.shippingMethod.method_id : this.shipping.shippingMethod,
          returnShippingAddress: this.returnShipping ? this.returnShipping : {},
          returnShippingMethodId: this.returnShipping.shippingMethod ? this.returnShipping.shippingMethod : 'on-site',
          shippingCarrierCode: this.shippingMethod.carrier_code ? this.shippingMethod.carrier_code : this.shipping.shippingCarrier,
          payment_method_code: this.getPaymentMethod(),
          payment_method_additional: this.payment.paymentMethodAdditional,
          shippingExtraFields: this.shipping.extraFields
        },
        user_note: this.getUserNote
      }
      // not sure why is this here.. ? TODO to check what was the meaning of this.
      if (!this.isVirtualCart) {
        this.order.addressInformation.shippingAddress = {
          region: this.shipping.state,
          region_id: this.shipping.region_id ? this.shipping.region_id : 0,
          country_id: this.shipping.country ? this.shipping.country : 'CZ',
          street: [this.shipping.streetAddress, this.shipping.apartmentNumber],
          company: this.shipping.company ? this.shipping.company : null,
          telephone: this.shipping.phoneNumber,
          postcode: this.shipping.zipCode,
          city: this.shipping.city,
          firstname: this.shipping.firstName,
          lastname: this.shipping.lastName,
          email: this.personalDetails.emailAddress,
          region_code: this.shipping.region_code ? this.shipping.region_code : ''
        }
        this.order.addressInformation.returnShippingAddress = {
          region: this.returnShipping.state,
          region_id: this.returnShipping.region_id ? this.returnShipping.region_id : 0,
          country_id: this.returnShipping.country ? this.returnShipping.country : 'CZ',
          street: [this.returnShipping.streetAddress, this.returnShipping.apartmentNumber],
          company: this.returnShipping.company ? this.returnShipping.company : null,
          telephone: this.returnShipping.phoneNumber,
          postcode: this.returnShipping.zipCode,
          city: this.returnShipping.city,
          firstname: this.returnShipping.firstName,
          lastname: this.returnShipping.lastName,
          email: this.personalDetails.emailAddress,
          region_code: this.returnShipping.region_code ? this.returnShipping.region_code : ''
        }
      }
      return this.order
    },
    placeOrder () {
      this.checkConnection({ online: typeof navigator !== 'undefined' ? navigator.onLine : true })
      if (this.checkStocks()) {
        this.$store.dispatch('checkout/placeOrder', { order: this.prepareOrder() })
      } else {
        this.notifyNotAvailable()
      }
    },
    savePersonalDetails () {
      this.$store.dispatch('checkout/savePersonalDetails', this.personalDetails)
    },
    saveShippingDetails () {
      this.$store.dispatch('checkout/saveShippingDetails', this.shipping)
    },
    savePaymentDetails () {
      this.$store.dispatch('checkout/savePaymentDetails', this.payment)
    },
    focusField (fieldName) {
      if (fieldName === 'password') {
        window.scrollTo(0, 0)
        this.activateSection('personalDetails')
        this.focusedField = fieldName
      }
      if (fieldName === 'email-address') {
        window.scrollTo(0, 0)
        this.activateSection('personalDetails')
        this.focusedField = fieldName
      }
    }
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || i18n.t('Checkout'),
      meta: this.$route.meta.description ? [{ vmid: 'description', name: 'description', content: this.$route.meta.description }] : []
    }
  },
  asyncData ({ store, route, context }) { // this is for SSR purposes to prefetch data
    return new Promise((resolve, reject) => {
      if (context) context.output.cacheTags.add(`checkout`)
      if (context) context.server.response.redirect('/')
      resolve()
    })
  }
}
