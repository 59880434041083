export enum ModalType {
  GDPR = 'gdpr',
  BIOMETRIC = 'biometric',
  TERMS = 'terms'
}

export const modalTypeToSlugMapping: Record<ModalType, string> = {
  [ModalType.GDPR]: 'zasady-ochrany-osobnich-udaju',
  [ModalType.BIOMETRIC]: 'predani-biometrickych-udaju',
  [ModalType.TERMS]: 'obchodni-podminky'
}
