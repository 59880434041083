<template>
  <div @focusout="(event)=>handleFocusOut(event)" tabindex="-1" class="dropdown" :id="`aselect_${getId}`">
    <button :disabled="disabled" @click="handleDropdownClicked"
            :id="`aselectButton_${getId}`"
            :class="`dropdown-button ${disabled? 'dropdown-button--disabled':'dropdown-button--active'}`"
    >
      <div>
        <div :class="`dropdown-option-label ${isOpen && 'color-silver'} `">
          {{ selectedOption.name }}
        </div>
        <div :class="`dropdown-arrow ${isOpen && 'dropdown-arrow--rotated'}`">
          <svg fill="black" xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation"
               class="vs__open-indicator"
          >
            <path
              fill="black"
              d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"
            />
          </svg>
        </div>
      </div>
    </button>
    <div v-if="isOpen" class="dropdown-content" :id="`aselectDropdown_${getId}`">
      <div v-for="option in options" :key="option" @click="()=>handleSelectOption(option)" class="dropdown-option">
        <div class="dropdown-option-label">
          {{ option.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script type='text/babel'>
export default {
  name: 'Aselect',
  props: {
    options: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    onOptionSelect: {
      type: Function,
      required: false
    },
    value: {
      type: Object,
      required: false
    }
  },
  computed: {
    getId () {
      if (this.id) {
        return this.id;
      }
      return Math.random().toString(36).substr(2, 9);
    }
  },
  data () {
    return {
      selectedOption: { name: 'Select option now!' },
      isOpen: false
    };
  },
  beforeMount () {
    if (this.options && this.options.length) {
      this.selectedOption = this.options[0];
      if (this.value) {
        this.selectedOption = this.value;
      }
    }
  },
  methods: {
    handleFocusOut (event) {
      if (!event.relatedTarget || this.getIdFromString(event.relatedTarget.id) !== this.getId) {
        this.isOpen = false;
      }
    },
    getIdFromString (str) {
      const strArr = str.split('_');
      return strArr[strArr.length - 1];
    },
    handleSelectOption (option) {
      if (this.selectedOption) {
        this.selectedOption = option;
        if (this.onOptionSelect) {
          this.onOptionSelect(option);
        }
      }
      this.isOpen = false;
    },
    handleDropdownClicked () {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style lang='scss'>

@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/components/_checkboxes';
@import '~theme/css/layout/_margin';

$color-silver: color(silver);
$color-active: color(anyrent);
$color-white: color(white);
$color-anyrent: color(anyrent);
$color-matterhorn: color(matterhorn);
.dropdown {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 30px;
  outline: none;
}

.dropdown-button {
  padding: 0;
  width: 100%;
  height: 100%;
  border: 1px solid $color-silver;
  border-radius: 4px;
}

.color-silver {
  color: $color-silver;
}

.dropdown-button--open {
  border-bottom: none;
}

.dropdown-button--disabled, .dropdown-button--disabled:active {
  background-color: #f1f1f1;
  cursor: not-allowed;
}

.dropdown-button--active {
  background-color: white;
  cursor: pointer;
}

button:active {
  background-color: white;
}

button:focus {
  background-color: white;
}

.dropdown-option-label {
  float: left;
  margin-left: 9px;
  font-size: 18px;
  font-family: TTCommons-Regular, SansSerif;
}

.dropdown-arrow {
  margin-top: 3px;
  float: right;
  margin-right: 15px;
}

.dropdown-option {
  display: grid;
  grid-auto-flow: row;
  align-content: center;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: $color-anyrent;
  color: $color-white;
}

.dropdown-content {
  border-radius: 4px;
  width: 198px;
  position: absolute;
  margin-top: 5px;
  padding-bottom: 5px;
  background-color: $color-white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid $color-silver;
  z-index: 1;
}

.dropdown-arrow--rotated {
  transform: rotate(180deg);
}

</style>
