import { mapState, mapGetters } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import toString from 'lodash-es/toString'
import { Logger } from '@vue-storefront/core/lib/logger'
const Countries = require('@vue-storefront/i18n/resource/countries.json')

export const Shipping = {
  name: 'Shipping',
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  beforeDestroy () {
    this.$bus.$off('checkout-after-load', this.onCheckoutLoad)
    this.$bus.$off('checkout-after-personalDetails', this.onAfterPersonalDetails)
    this.$bus.$off('checkout-after-shippingset', this.onAfterShippingSet)
    this.$bus.$off('checkout-before-shippingMethods', this.onBeforeShippingMethods)
  },
  beforeMount () {
    this.$bus.$on('checkout-after-load', this.onCheckoutLoad)
    this.$bus.$on('checkout-after-personalDetails', this.onAfterPersonalDetails)
    this.$bus.$on('checkout-after-shippingset', this.onAfterShippingSet)
    this.$bus.$on('checkout-before-shippingMethods', this.onBeforeShippingMethods)
  },
  data () {
    return {
      isFilled: false,
      countries: Countries,
      // shipping: this.$store.state.checkout.shippingDetails,
      shipping: {
        shippingMethod: '',
        needsAddress: false
      },
      shipToMyAddress: false,
      myAddressDetails: {
        firstname: '',
        lastname: '',
        country: '',
        region: '',
        city: '',
        street: ['', ''],
        postcode: '',
        telephone: ''
      },
      defaultCountry: null
    }
  },
  computed: {
    ...mapState({
      currentUser: (state: RootState) => state.user.current
    }),
    ...mapGetters({
      shippingMethods: 'checkout/getShippingMethods'
    }),
    checkoutShippingDetails () {
      return this.$store.state.checkout.shippingDetails
    },
    paymentMethod () {
      return this.$store.getters['checkout/getPaymentMethods']
    }
  },
  watch: {
    shippingMethods: {
      handler () {
        this.checkDefaultShippingMethod()
      }
    },
    shipToMyAddress: {
      handler () {
        this.useMyAddress()
      },
      immediate: true
    },
    // current shipping data
    currentShip () {
      if (this.currentShip && this.currentShip.carrier_code) {
        if (this.currentShip.needs_address) {
          this.equipmentDelivery = true;
        } else {
          this.equipmentDelivery = false;
        }
        this.$store.dispatch('checkout/saveShippingDetails', this.shipping)
      } else {
        this.equipmentDelivery = false;
      }
    }
  },
  mounted () {
    this.checkDefaultShippingAddress()
    this.checkDefaultShippingMethod()
  },
  methods: {
    checkDefaultShippingAddress () {
      return this.hasShippingDetails
    },
    checkDefaultShippingMethod () {
      if (!this.shipping.shippingMethod || this.notInMethods(this.shipping.shippingMethod)) {
        let shipping = this.shippingMethods.find(item => item.default_address)

        if (!shipping && this.shippingMethods && this.shippingMethods.length > 0) {
          shipping = this.shippingMethods[0]
        }
        // we want users to check their option, so no pre-load
        this.shipping.country = '' // TODO
        this.shipping.shippingMethod = shipping.method_id
        this.shipping.needsAddress = shipping.needs_address
        this.shipping.shippingCarrier = shipping.carrier_code
      }
    },
    onAfterShippingSet (receivedData) {
      this.shipping = receivedData
      this.isFilled = true
    },
    onAfterPersonalDetails (receivedData) {
      if (!this.isFilled) {
        this.$store.dispatch('checkout/updatePropValue', ['firstName', receivedData.firstName])
        this.$store.dispatch('checkout/updatePropValue', ['lastName', receivedData.lastName])
      }
      // in case of null shipping
      if (this.shipping.country === null || this.shipping.country === '') {
        this.shipping.country = 'CZ'
      }
    },
    sendDataToCheckout () {
      this.$bus.$emit('checkout-after-shippingDetails', this.shipping, this.$v)
      this.$store.dispatch('checkout/saveShippingDetails', this.shipping)
      this.isFilled = true
    },
    edit () {
      if (this.isFilled) {
        this.$bus.$emit('checkout-before-edit', 'shipping')
      }
    },
    hasShippingDetails () {
      if (this.currentUser) {
        if (this.currentUser.hasOwnProperty('default_shipping')) {
          const id = this.currentUser.default_shipping
          const addresses = this.currentUser.addresses
          for (let i = 0; i < addresses.length; i++) {
            if (toString(addresses[i].id) === toString(id)) {
              this.myAddressDetails = addresses[i]
              return true
            }
          }
        }
      }
      return false
    },
    useMyAddress () {
      if (this.shipToMyAddress) {
        this.shipping = {
          firstName: this.myAddressDetails.firstname,
          lastName: this.myAddressDetails.lastname,
          country: this.myAddressDetails.country_id,
          state: this.myAddressDetails.region.region ? this.myAddressDetails.region.region : '',
          city: this.myAddressDetails.city,
          streetAddress: this.myAddressDetails.street[0],
          apartmentNumber: this.myAddressDetails.street[1],
          zipCode: this.myAddressDetails.postcode,
          phoneNumber: this.myAddressDetails.telephone,
          shippingMethod: this.checkoutShippingDetails.shippingMethod,
          shippingCarrier: this.checkoutShippingDetails.shippingCarrier
        }
      } else {
        this.shipping = this.checkoutShippingDetails
      }
      this.changeCountry()
    },
    getShippingMethod () {
      for (const method of this.shippingMethods) {
        if (method.method_id === this.shipping.shippingMethod) {
          return {
            drop_off_method_title: method.drop_off_method_title,
            method_title: method.method_title,
            price_incl_tax: method.price_incl_tax
          }
        }
      }
      return {
        method_title: '',
        price_incl_tax: ''
      }
    },
    getCountryName () {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].code === this.shipping.country) {
          return this.countries[i].name
        }
      }
      return ''
    },
    changeCountry () {
      this.$bus.$emit('checkout-before-shippingMethods', this.shipping.country)
    },
    getCurrentShippingMethod () {
      const shippingMethodId = this.shipping.shippingMethod
      const currentMethod = this.shippingMethods ? this.shippingMethods.find(item => item.method_id === shippingMethodId) : {}
      return currentMethod
    },
    changeShippingMethod () {
      const currentShippingMethod = this.getCurrentShippingMethod()

      if (currentShippingMethod) {
        this.shipping = Object.assign(this.shipping, {shippingCarrier: currentShippingMethod.carrier_code})
        this.$bus.$emit('checkout-after-shippingMethodChanged', {
          country: this.shipping.country,
          method_code: currentShippingMethod.method_code,
          carrier_code: currentShippingMethod.carrier_code,
          payment_method: this.paymentMethod[0].code,
          method_id: currentShippingMethod.method_id
        })
      }
    },
    notInMethods (method) {
      const availableMethods = this.shippingMethods
      if (availableMethods.find(item => item.method_id === method)) {
        return false
      }
      return true
    },
    onCheckoutLoad () {
      if (this.$store.state.checkout.shippingDetails) {
        // this.shipping = this.$store.state.checkout.shippingDetails
      }
    },
    onBeforeShippingMethods (country) {
      this.defaultCountry = country
      this.shipping.country = country
    }
  }
}
