<template>
  <div class="pb30">
    <div class="row pl30 pt0 pr30 pb10 between-xs">
      <div class="blend overflow-hidden">
        <product-image :image="image" />
      </div>
      <div class="col-xs ml10">
        <!--        Row for name-->
        <div class="row">
          <div class="col-xs-12 col-md-12 pb15">
            <div class="mb5">
              <div class="h4 weight-400 cl-accent serif overflow-wrap-anywhere">
                {{ product.name | htmlDecode }}
              </div>
              <div class="error" v-if="product.errors && Object.keys(product.errors).length > 0">
                {{ product.errors | formatProductMessages }}
              </div>
            </div>
          </div>
          <!--            Product options-->
          <div class="col-md-6 col-xs-6">
            <div class="h5 cl-bg-tertiary pt5 options" v-if="product.totals && product.totals.options">
              <div v-for="opt in product.totals.options" :key="opt.label">
                <span class="opn">{{ $t(opt.label + '_filter') }}: </span>
                <span class="opv" v-html="opt.value" />
              </div>
            </div>
            <div class="h5 cl-bg-tertiary pt5 options" v-else-if="product.options">
              <div v-for="opt in product.options" :key="opt.label">
                <span class="opn">{{ $t(opt.label + '_filter') }}: </span>
                <span class="opv" v-html="opt.value" />
              </div>
            </div>
            <div>
              <div>
                <span class="h5 cl-secondary">
                  {{ $t('Qty') }}
                  <span class="">
                    {{ product.qty }}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <!--            Product price-->
          <div class="col-xs-6 col-md-6 serif justify-self-end color-matterhorn">
            <div v-if="isOnline && product.totals">
              <span class="h4 cl-error float-right" v-if="product.totals.discount_amount">
                {{
                  (product.totals.row_total - product.totals.discount_amount + product.totals.tax_amount)
                    | price(storeView)
                }}
              </span>
              <span class="price-original h5 float-right" v-if="product.totals.discount_amount">
                f {{ product.totals.row_total_incl_tax | price(storeView) }}</span>
              <span v-if="!product.totals.discount_amount" class="h4">
                e {{ product.totals.row_total_incl_tax | price(storeView) }}</span>
            </div>
            <div v-else>
              <span v-if="!product.special_price" class="h4 float-right">{{ intervalPrice | price(storeView) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="service in product.selectedServices" :key="service.id" class="row pb0 pt0 pl30 pr30 to">
      <div class="blend" />
      <div class="col-xs ml10 h4 weight-400 cl-accent serif">
        {{ service.name }}
      </div>
      <div class="col-xs align-right cl-accent h4">
        {{ service.price | price(storeView) }}
      </div>
    </div>
  </div>
</template>

<script>
import { Product } from '@vue-storefront/core/modules/checkout/components/Product';
import { onlineHelper } from '@vue-storefront/core/helpers';
import ProductImage from 'theme/components/core/ProductImage';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { Logger } from '@vue-storefront/core/lib/logger';

export default {

  mixins: [Product],
  components: {
    ProductImage
  },
  computed: {
    platformTotals () {
      return this.$store.getters['cart/getPlatformTotals'];
    },
    storeView () {
      return currentStoreView();
    },
    isOnline () {
      return onlineHelper.isOnline;
    },
    image () {
      return {
        loading: this.thumbnail,
        src: this.thumbnail
      };
    },
    dateFrom: {
      get () {
        return this.$store.getters['reservations/getCurrentDates'].from;
      },
      set (date) {
        return this.$store.dispatch('reservations/setDateFrom', date);
      }
    },
    intervalPrice () {
      return this.getIntervalPrice(this.$store.getters['cart/getPlatformTotals']) || '';
    },
    dateTo: {
      get () {
        return this.$store.getters['reservations/getCurrentDates'].to;
      },
      set (date) {
        return this.$store.dispatch('reservations/setDateTo', date);
      }
    }
  },
  methods: {
    getIntervalPrice (platformTotals) {
      if (!platformTotals) {
        Logger.error('totals', 'totals arent accessible', platformTotals)();
        return this.product.price;
      }

      const totalItems = platformTotals.items;

      if (!totalItems) {
        return this.product.price;
      }

      const child = this.product.configurable_children.find((configurableChildren) => {
        return configurableChildren.sku === this.product.sku;
      });

      if (!child) {
        Logger.error('intervalPrice', 'unable to match totals and product.. sku mismatch', this.product.configurable_children)();
        return this.product.price;
      }

      for (const item of totalItems) {
        const result = child.items.find((childItem) => {
          return childItem.id === item.item_id;
        });
        // if there's an item in the child that has the same id as
        // one of the objects in platform.totals, then take it's price
        if (result) {
          return item.row_total;
        }
      }
      return this.product.price;
    }
  }
};
</script>

<style lang='scss' scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-matterhorn: color(matterhorn);

.price-original {
  text-decoration: line-through;
}
.justify-self-end {
  justify-self: end;
}
.float-right{
  float: right;
}

.color-matterhorn {
  color: $color-matterhorn;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.overflow-hidden {
  overflow: hidden;
}

.blend {
  flex: 0 0 150px;
}
</style>
